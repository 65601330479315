<template>
  <div>
    <PageTitle title="就诊记录" fixed backTo="/visit"></PageTitle>
    <List :width="100">
      <div style="position: relative;width: 85%;margin: 15px auto auto auto;">
        <ListItem :leftWidth="0" :rightWidth="100" :style="height">
          <template #top>
            <p>处方详情</p>
          </template>
          <template #right>
            <van-divider :style="{ borderColor: '#D8D8D8', margin: '5px 0' }"></van-divider>
            <div>
              <p>
                就诊卡号：{{
                  item.jzkh && item.jzkh.substring(0, 4) + "****" + item.jzkh.substring(item.jzkh.length - 4, item.jzkh.length)
                }}</p>
            </div>
            <div style="float: left">
              <p>姓名：{{ item.xm }}</p>
              <p>年龄：{{ item.nl }}</p>
            </div>
            <div style="float: left;margin-left: 50px">
              <p>性别：{{ item.xb }}</p>
              <p>民族：{{ item.mz }}</p>
            </div>
            <div style="clear: both" class="div-mt">
              <p>执业点：{{ item.ksmc }}</p>
              <p>开方医生：{{ item.ygxm }}</p>
              <van-divider :style="{ borderColor: '#D8D8D8', margin: '5px 0' }"></van-divider>
              <div v-if="item.pd=='1'&&item.diagnosisList[0].jbmc!=null">
                <p>诊断：</p>
                <div v-for="(v1,index) in item.diagnosisList" :key="index">
                  <div>
                    <p>{{ index + 1 }}.{{ v1.jbmc }}</p>
                  </div>
                </div>
              </div>
              <div v-else>
                <p>诊断：未开具</p>
              </div>
            </div>

            <van-divider :style="{ borderColor: '#D8D8D8', margin: '5px 0 0 0' }"></van-divider>
            <!--            <div v-if="item.pd=='1'&&item.ypxx1List[0].ypmc!=null">-->
            <!--              <p style="padding-top: 10px">药品名称：</p>-->
            <!--              <div v-for="(v,index) in item.ypxx1List" :key="index">-->
            <!--                <p>{{ index + 1 }}.{{ v.ypmc }} ({{ v.yfgg }}) 共{{ v.ypsl }}{{ v.yfdw }}</p>-->
            <!--                <p style="color: #76B49C;margin-left: 10px;">用法：{{ v.cfbz }} {{ v.xmmc }}</p>-->
            <!--              </div>-->
            <!--            </div>-->
            <div v-for="(detail, index) in prescriptionDetailsList" :key="index">
              <div style="padding-top: 5px;font-size: 14px;color: #1678FD">处方{{ index + 1 }}：
                <button class="pay-btn" v-show="detail.cfpayflag == '0'" @click="pay('oneDrug', detail)">支付</button>
                <button class="pay-btn" v-show="detail.cfpayflag == '1'"
                        @click="refundCF(detail.cftradeNum, detail.totalFee, detail.fphm)">退款
                </button>
              </div>
              <div v-for="(drug, drugIndex) in detail.cfypxx" :key="'drug' + drugIndex">
                <p>{{ drugIndex + 1 }}.{{ drug.ypmc }} ({{ drug.yfgg }}) 共{{ drug.ypsl }}{{ drug.yfdw }}</p>
                <p style="margin-left: 10px;">用法：{{ drug.gytj }}</p>
                <p style="margin-left: 10px;">单价：{{ drug.ypdj }} <span
                    style="margin-left: 10px">合计金额：{{ drug.hjje }}</span></p>
              </div>
            </div>
            <van-divider :style="{ borderColor: '#D8D8D8', margin: '5px 0' }"></van-divider>
            <div class="div-mt width-half">
              <div><p>挂号费：</p></div>
              <div v-if="item.ghf!='暂无'"><p>￥{{ item.applytype ? (item.applytype[0] != '云门诊' ? item.consultFee : item.ghf) : item.ghf }}元</p></div>
              <div v-else><p>{{ item.ghf }}</p></div>
              <div><p>药品费用：</p></div>
              <div v-if="item.totalFee!='暂无'"><p>￥{{ item.totalFee }}元</p></div>
              <div v-else><p>{{ item.totalFee }}</p></div>
              <div><p>药师审核费：</p></div>
              <div><p>{{ item.ysshf }}</p></div>
              <div><p>快递费：</p></div>
              <div><p>{{ item.kdf }}</p></div>
            </div>
            <div style="float: right">
              <p style="font-size: 14px" v-if="item.hjfy!='暂无'">合计费用 ￥{{ item.hjfy }}元</p>
              <p style="font-size: 14px" v-else>合计费用 {{ item.hjfy }}</p>
            </div>
            <div style="margin-top: 30px">
              <p>开具时间：{{ GetSJS(item.yysj) }}</p>
            </div>
          </template>
        </ListItem>
        <ListItem :leftWidth="50" :rightWidth="50">
          <template v-slot:bottom>
            <p style="text-align: center;">我的咨询</p>
            <div class="borderColor" style="margin-top: 10px" v-if="ZX[0]"
                 @click="TZ(ZX[0].targetId,ZX[0].applyInfoId,ZX[0].avatarUrl)">
              <van-icon :badge="ZX[0].unReadNum"
                        style="float: right"
                        v-if="ZX[0].unReadNum!='0'"/>
              <div class="leftArea">
                <img :src="ZX[0].avatarUrl || defaultDoctorPicture" style="height:44px;width:44px;border-radius: 50%;float: left">
                <p class="fontmd" style="float: left;margin-left: 5px">{{ ZX[0].doctorName }}</p>
                <p class="fontsm" style="float: left;margin-top: 3px;margin-left: 2px">{{ ZX[0].doctorLevel }}</p>
                <p class="fontsm" style="float: right;margin-left: 2px;margin-top: 3px">
                  {{ GetSJSS(ZX[0].sendDate) }}</p>
                <p class="fontmd" style="margin-left: 50px; margin-top: 30px;color: #1989FA; ">{{ ZX[0].msg }}</p>
              </div>
            </div>
          </template>

          <!-- 输入任意文本 -->
        </ListItem>
      </div>
    </List>
    <!--    <van-button type="primary" block @click="GetZLYD()" style="width: 85%;bottom:0;position: fixed;margin-left: 7.5%;margin-bottom: 15%" v-if="dqzt.includes('已发货')">-->
    <!--      再来一单-->
    <!--    </van-button>-->
    <div style="width: 85%;bottom:0;position: fixed;margin-left: 7.5%;margin-bottom: 5%">
      <div style="display: flex; justify-content: space-between">
        <van-button type="primary" @click="pay('appointment')" v-if="payflag=='0'">
          支付诊疗费
        </van-button>
        <!--        <van-button type="primary" @click="pay('drug')" v-if="payflag=='1' && newcfpayflag > 0">-->
        <!--          支付药品费-->
        <!--        </van-button>-->
        <!--        <van-button type="primary" v-if="newcfpayflag = 0 && !dqzt.includes('已发货')">-->
        <!--          退款-->
        <!--        </van-button>-->
        <van-button type="primary" @click="showModal()" v-if="dqzt=='未就诊'">
          取消申请
        </van-button>
      </div>
    </div>
    <!--    <van-button type="primary" block @click="pay()" style="width: 85%;bottom:0;position: fixed;margin-left: 7.5%;margin-bottom: 5%" v-if="payflag=='0'">-->
    <!--      支付挂号费-->
    <!--    </van-button>-->

    <!--    <van-button type="primary" block @click="GetZLYD()"-->
    <!--                style="width: 85%;bottom:0;position: fixed;margin-left: 7.5%;margin-bottom: 5%" v-if="item.sfzlyd=='1'">-->
    <!--      再来一单-->
    <!--    </van-button>-->
    <!--    <div style="width: 85%;bottom:0;position: fixed;margin-left: 7.5%;" v-if="pd == 0">-->
    <!--      <van-button type="primary" block @click="showModal()" style="margin-bottom: 5%">取消申请</van-button>-->
    <!--      <van-button type="primary" block @click="pay()" style="margin-bottom: 5%">-->
    <!--        支付-->
    <!--      </van-button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle.vue';
import List from '@/components/home-page/List.vue';
import ListItem from '@/components/home-page/ListItem.vue';
import {
  GetJLXQ,
  GetDDZX,
  CancelApplication,
  ConfirmRefund,
  InvoiceVoided,
  PrescriptionDetailsList,
  AMP_HOS_002
} from '@/api/check.js';
import {AliRefund} from '@/api/weChart.js';

export default {
  components: {
    PageTitle,
    List,
    ListItem,
  },
  data() {
    return {
      items: [],
      item: {}
//                    {"sfzlyd":"0","sjrxm":"","sjrhm":null,"ddbm":1307356418,"jzkh":"","xm":"","xb":"","nl":0,"mz":"","ksmc":"","ygxm":"","jbmc":"未开具","rp":"暂无","sig":"暂无","ghf":0,"ghF1":"暂无","totalFee":0,"totalFee1":"暂无","ysshf":"暂无","kdf":"暂无","hjfy":0,"hjfY1":"暂无","yysj":null,"kjsj":"暂无","sflyzq":"来院自取","sjrxxdz":null,"sjrsf":null,"sjrqs":null,"sjrxj":null,"sjrzj":null,"diagnosisList":[],"ypxx1List":[]}
      ,
      height: {
        height: '',
        overflow: 'auto',
      },
      height1: {
        height: '',
        overflow: 'auto',
      },
      margin: {
        left: '',
      },
      jzxh: this.$route.params.jzxh,
      pd: this.$route.params.pd,
      payflag: this.$route.params.payflag,
      dqzt: this.$route.params.dqzt,
      newcfpayflag: this.$route.params.cfpayflag,
      lock: false,
      ZX: "",
      defaultDoctorPicture: require('../assets/default_doctor.png'),
      prescriptionDetailsList: [],//获取到的处方详情列表
    }
  },
  methods: {
    hh() {
      GetJLXQ(this.jzxh, this.pd).then(
          (result) => {
            this.item = result;
            this.item.pd = this.pd;
            if (this.pd != "1") {
              this.item.ghf = result.ghF1;
              this.item.totalFee = result.totalFee1;
              this.item.hjfy = result.hjfY1;
              this.item.yysj = "暂无";
            }
            console.log(this.item)
            localStorage.setItem('visitDetail', JSON.stringify(this.item));
            GetDDZX(result.ddbm).then(
                (results) => {
                  this.ZX = results;
                  this.items = this.ZX;
                  this.doConnect(this);
                },
                () => {
                }
            );
          },
          () => {
          }
      );
    },
    TZ(e, f, h) {
      this.$router.push({path: '/consult/' + e + '/' + f, query: {image: h}});
      localStorage.setItem('address1', this.jzxh);
      localStorage.setItem('address2', this.pd);
      localStorage.setItem('address3', this.payflag);
      localStorage.setItem('address4', this.dqzt);
      localStorage.setItem('address5', this.cfpayflag);
      localStorage.setItem('payOpenIds', "");
    },
    doConnect(app) {
      let that = this;
      if (this.$store.SaveSocket && this.$store.SaveSocket.readyState == WebSocket.OPEN) {
        this.socket = this.$store.SaveSocket;
      } else {
        let uri = process.env.VUE_APP_API_WSS_URL + '/ws?senderId=patient-' + app.ZX[0].senderId + '&targetId=-1'
        this.socket = new WebSocket(uri);
        this.$store.SaveSocket = this.socket;//webscoket实例 存进全局
      }
      this.socket.onopen = function () {
        that.heartbeatInstance = setInterval(() => {
          if (that.socket && that.socket.readyState == WebSocket.OPEN) {
            that.heartbeat();
          } else {
            if (that.heartbeatInstance) {
              clearInterval(that.heartbeatInstance);
            }
          }
        }, 50000, that)
      };

      this.socket.onclose = function () {
        if (!that.isDestroyed) {
          setTimeout(() => {
            that.doConnect(that);
          }, 5000, that)
        }
      };

      this.socket.onmessage = function (e) {

        let msg = JSON.parse(e.data);
        if (msg.SenderId == '-1' && msg.MsgType != "6") {
          app.items.unReadNum = msg.totalUnReadNum;
          app.items.detail[0].msg.title = JSON.parse(msg.Msg).title;
          app.items.detail[0].sendDate = msg.SendDate
          app.items.detail[0].msg.content = JSON.parse(msg.Msg).content;
        } else {
          for (let m = 0; m < app.ZX.length; m++) {
            if (msg.SenderId == app.ZX[m].targetId) {
              app.ZX[m].unReadNum = msg.unReadNum
              app.ZX[m].msg = msg.Msg
              app.ZX[m].sendDate = msg.SendDate
              app.items[m].unReadNum = msg.unReadNum
              app.items[m].msg = msg.Msg
              app.items[m].sendDate = msg.SendDate
            }
          }
        }
      }
    },
    heartbeat() {
      this.socket.send(JSON.stringify({
        msg: '',
        msgtype: "6",
        senderid: 'patient-' + this.ZX[0].senderId,
        targetId: -1,
        id: ''
      }));
    },
    GetZLYD() {
      //通过push进行跳转
      this.$router.push('/encore/' + this.jzxh)
    },
    GD() {
      this.height.height = document.documentElement.clientHeight - 290 + 'px';
      this.height1.height = 120 + 'px';
      this.margin.left = (document.documentElement.clientWidth - 150) / 2 + 'px';
    },
    GetSJ(e) {
      if (e != null) {
        if (this.$util.moment(e).format('YYYY-MM-DD') == this.$util.moment().format('YYYY-MM-DD')) {
          return this.$util.moment(e).format('HH:mm')
        } else {
//                        return "暂无";
        }
      }
    },
    GetSJSS(e) {
      if (e != null) {
        if (this.$util.moment(e).format('YYYY-MM-DD') == this.$util.moment().format('YYYY-MM-DD')) {
          return this.$util.moment(e).format('HH:mm')
        } else {
          return this.$util.moment(e).format('YYYY-MM-DD')
        }
      }
    },
    GetSJS(e) {

      if (e != "暂无" && e != null) {

        return this.$util.moment(e).format('YYYY-MM-DD HH:mm:ss')

      } else {
        return "暂无";
      }
    },
    pay(type, prescription = {}) {
      if (type == 'appointment') {
        this.item.jsdm = this.item.sjdm;
        this.item.certno = this.item.sfzh;
        this.item.applyType = this.item.applytype ? this.item.applytype[0] : '云门诊';
        my.navigateTo({url: `/pages/Settlement/Settlement?type=${type}&param=${JSON.stringify(this.item)}`});
      } else {
        const params = {
          ypxx1List: prescription,//this.item.ypxx1List,
          totalFee: prescription.hjje,//this.item.newtotalFee > 0 ? this.item.newtotalFee : this.item.totalFee,
          ksmc: this.item.ksmc,
          ygxm: this.item.ygxm,
          yysj: this.item.yysj,
          knxx: this.item.sfzh,
          xm: this.item.xm,
          brid: this.item.patientid,
          doctorId: this.item.doctorId,
          hoscode: this.item.hoscode,
          certno: this.item.sfzh,
        }
        console.log(params);
        my.navigateTo({url: `/pages/Settlement/Settlement?type=${type}&param=${JSON.stringify(params)}`});
      }
    },
    refundCF(tradenum, fee, fphm) {
      // let that = this;
      this.$dialog.confirm({
        title: '',
        message: '是否确认退款',
      }).then(() => {
        if(fphm.length) {
          if (fphm.includes('ZFB')) {
            InvoiceVoided(0, 2, fphm).then(() => {
              AliRefund(tradenum, fee).then(res => {
                this.$toast('退款成功');
                location.reload();
              })
              // this.$router.push({name: 'Visit'})
            }).catch((err) => {
              // this.$toast(err);
            });
          } else {
            AMP_HOS_002(2, fphm).then(res => {
              this.$toast('医保退款成功');
              location.reload();
            }).catch((err) => {
              this.$toast(err);
            });
            // this.$toast('医保支付退款暂未开放');
          }
        }
      });
    },
    showModal() {
      let that = this;
      this.$dialog.confirm({
        title: '',
        message: '是否确认取消申请',
      }).then(() => {
        if (that.item.payflag == '0') {
          CancelApplication(that.item.ddbm).then(() => {
            this.$toast('取消申请成功');
            this.$router.push({name: 'Visit'})
          })
        } else {
          if(that.item.tradeNum){
            AliRefund(that.item.tradeNum, Number(that.item.ghf)).then((res)=>{
              ConfirmRefund(that.item.sjdm).then(() => {
                CancelApplication(that.item.ddbm).then(() => {
                  // InvoiceVoided(0,1, that.item.applyid).then(()=>{
                    this.$toast('取消申请成功');
                    this.$router.push({name: 'Visit'})
                  // }).catch((err)=>{
                  //   this.$toast(err);
                  // });
                }).catch((err)=>{
                  this.$toast(err);
                });
              }).catch((err)=>{
                this.$toast(err);
              });
            }).catch((err)=>{
              this.$toast(err);
            });
          }else{
            AMP_HOS_002(1, that.item.sjdm).then(res => {
              // ConfirmRefund(that.item.sjdm).then(() => {
                CancelApplication(that.item.ddbm).then(() => {
                  this.$toast('医保退款成功');
                  this.$router.push({name: 'Visit'});
                }).catch((err)=>{
                  this.$toast(err);
                });
              // }).catch((err)=>{*/
              //   this.$toast(err);
              // });
            }).catch((err) => {
              this.$toast(err);
            });
            // this.$toast('医保支付退款暂未开放');
          }
        }
        // console.log(this.$router.push('/visit'))
      }).catch(() => {

      });
    },
  },
  created: function () {
    // init sendMsg object
    this.hh();
    this.GD();
    PrescriptionDetailsList(this.jzxh).then(//获取云医院处方详情列表
        (result) => {
          this.prescriptionDetailsList = result;
        },
        () => {
          this.prescriptionDetailsList = [];
        }
    );
  },
  mounted() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://appx/web-view.min.js';
    document.body.appendChild(script);
  },
}
</script>

<style scoped lang="less">
@import "../style/var.less";

.div-mt {
  margin-top: 10px;
}

.width-half {
  div {
    width: 50%;
    display: inline-block;
  }

  div:nth-child(2n) p {
    float: right;
    color: #FF2600;
  }

  div:nth-child(2n+1) p {
    float: left;
  }
}

.infoContainer {
  padding: 10px;

  p {
    margin: 0;
  }

  .top p {
    text-align: center;
    margin: 0 0 10px;
  }

  .right {
    p {
      font-size: @font-size-sm;
    }
  }
}

.borderColor {
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 10px;
  margin: 0 10px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .leftArea {
    width: 100%;
    display: inline-block;

  }

  .rightArea {
    width: 15%;
    display: inline-block;
    vertical-align: top;

    p {
      float: right;
    }

    .van-icon {
      float: right;
      margin-top: 40px;
    }
  }
}

.fontmd {
  font-size: @font-size-md;
}

.fontsm {
  font-size: @font-size-sm;
}

.div-mt {
  margin-top: 10px;
}

.width-half {
  div {
    width: 50%;
    display: inline-block;
  }

  div:nth-child(2n) p {
    float: right;
    color: #FF2600;
  }

  div:nth-child(2n+1) p {
    float: left;
  }
}

.pay-btn {
  padding: 0 10px;
  color: @white;
  background: @green;
  border: 1px @green;
  border-radius: 5px;
  margin-left: 10px;
  float: right;
}
</style>
