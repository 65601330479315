import { render, staticRenderFns } from "./VisitMX.vue?vue&type=template&id=55c3b4e4&scoped=true&"
import script from "./VisitMX.vue?vue&type=script&lang=js&"
export * from "./VisitMX.vue?vue&type=script&lang=js&"
import style0 from "./VisitMX.vue?vue&type=style&index=0&id=55c3b4e4&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c3b4e4",
  null
  
)

export default component.exports